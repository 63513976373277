import React from 'react'
import './introduction.css'

const Introduction = () => (
  <header>
    <h5>
      Hi, I'm <span className="highlight">Girija</span>
    </h5>
    <p className="highlight-sm">
      A{' '}
      <a
        href="https://360software-engineers.com"
        id="360-se-link"
        target="_blank"
        rel="noreferrer"
        name="360 Software Engineer"
        title="360 Software Engineer"
      >
        360 software engineer
      </a>{' '}
      who is solving complex business problems, a learner who is always
      learning, a mentor helping create the next generation of 360 software
      engineers and a leader leading by examples. As a professional, I have
      architected, designed, developed, scaled{' '}
      <i> event driven cloud native enterprise applications </i> using{' '}
      <i>Domain Driven Design, Microservices, Serverless </i> and{' '}
      <i> Micro Frontends </i> for various FinTechs across the globe.
    </p>

    <p className="highlight-sm">
      I like to build anything that makes life simpler for developers, business,
      customers and everyone in between. Believe strongly in purpose-built
      simple cohesive systems working together to solve bigger problems. Love to
      get my hands dirty with the emerging technologies, understand their
      trade-offs and use them where they fit best.
    </p>
  </header>
)

export default Introduction
