import React from 'react'
import DefaultLayout from '../components/DefaultLayout/default-layout'

import Introduction from '../components/Introduction/introduction'
import ShortNote from '../components/ShortNote/shortnote'
import Certification from '../components/Certification/certification'
import About from '../components/About/about'

import './index.css'

const IndexPage = () => (
  <DefaultLayout>
    <main className="content">
      <div className="content-container">
        <Certification />
        <Introduction />
        <ShortNote />
      </div>
      <div className="about-container">
        <About />
      </div>
    </main>
  </DefaultLayout>
)

export default IndexPage
