import React from 'react'

import Contact from '../Contact/contact'

import './about.css'

import profileImage from '../../assets/images/girija.png'

const About = () => (
  <section className="about">
    <div className="wrapper">
      <header>
        <img
          className="profile-image"
          src={profileImage}
          alt="Girija"
          title="Girija's image"
        />
        <p className="about-text">
          A technology enthusiast, loves travelling, capturing landscapes,
          riding bikes and follows cricket.
        </p>
      </header>
      <Contact />
    </div>
  </section>
)

export default About
