import React from 'react'

import './contact.css'

import githubIcon from '../../assets/icons/github.png'
import linkedinIcon from '../../assets/icons/linkedin.png'
import mediumIcon from '../../assets/icons/medium.png'
import stackOverflowIcon from '../../assets/icons/stack-overflow.png'
import emailIcon from '../../assets/icons/email.png'

const Contact = () => (
  <div className="contact">
    <ul>
      <li>
        <a
          href="https://www.linkedin.com/in/gsswain"
          target="_blank"
          rel="noreferrer"
          name="Girija's Linkedin profile"
          title="Visit Girija's Linkedin profile"
        >
          <img src={linkedinIcon} alt="Linkedin" />
        </a>
      </li>
      <li>
        <a
          href="https://stackoverflow.com/users/4601120/gsswain"
          target="_blank"
          rel="noreferrer"
          name="Girija's Stack Overflow profile"
          title="Visit Girija's Stack Overflow profile"
        >
          <img src={stackOverflowIcon} alt="Stack Overflow" />
        </a>
      </li>
      <li>
        <a
          href="https://github.com/GSSwain"
          target="_blank"
          rel="noreferrer"
          name="Girija's Github profile"
          title="Visit Girija's Github profile"
        >
          <img src={githubIcon} alt="Github" />
        </a>
      </li>
      <li>
        <a
          href="https://gsswain.medium.com/"
          target="_blank"
          rel="noreferrer"
          name="Girija's Posts on Medium"
          title="Visit Girija's Medium page"
        >
          <img src={mediumIcon} alt="Medium" />
        </a>
      </li>
      <li>
        <a
          href="mailto:contact@gsswain.com"
          name="Girija's email"
          title="Send an email to Girija"
        >
          <img src={emailIcon} alt="email" />
        </a>
      </li>
    </ul>
  </div>
)

export default Contact
